@@ -0,0 +1,692 @@
<template>
  <Modal size="4xl">
    <div class="absolute ml-3">
      <a-icon type="user" :style="{ fontSize: '24px' }"></a-icon>
    </div>
    <h1 class="text-3xl mb-2 text-blue-700 font-bold">Datos de contacto</h1>
    <form @submit.prevent="saveSettings">
      <div class="modal-content-scroll my-5 px-5 pb-6 rounded-xl border mx-2">
        <div class="w-full mb-2">
          <BaseRadio
            ref="personType"
            name="personType"
            id="personType"
            label="Tipo de persona"
            :radioOptions="formOptions.optionsPersonType"
            v-model="form.person_type_cod"
          />
        </div>
        <div v-if="form.person_type_cod">
          <!-- Natural -->
          <div
            v-if="form.person_type_cod === 1"
            class="grid gap-5 grid-cols-1 md:grid-cols-2"
          >
            <BaseInput
              ref="fullName"
              type="text"
              label="Nombre completo"
              v-model="form.first_name"
              placeholder="Ej: Valentina María"
              :required="true"
              :maxlength="25"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <BaseInput
              ref="lastName"
              type="text"
              label="Apellidos"
              v-model="form.last_name"
              placeholder="Ej: Jiménez Gonzalez"
              :required="true"
              :maxlength="24"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <BaseSelect
              ref="identificationType"
              label="Tipo de identificación"
              :selectOptions="customIdentificationType"
              v-model="form.identification_type_cod"
              :required="true"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <div class="feedback-container">
              <BaseInput
                ref="identificationNumber"
                type="text"
                label="Número de identificación"
                placeholder="Ej: 1144578546 (sin puntos)"
                v-model="form.identification_number"
                :required="true"
                :identification="form.identification_type_cod"
                :maxlength="15"
                :style="{ marginTop: '0.5rem !important' }"
              />
              <div v-if="form.identification_number !== '' && !validPpt()">
                <p class="mt-1 text-xs text-left text-red-500">
                  {{ feedbackIdentificationNumber }}
                </p>
              </div>
            </div>

            <BaseDate
              ref="birthDate"
              type="text"
              label="Fecha de nacimiento"
              v-model="form.birth_date"
              placeholder="DD/MM/AAAA"
              :style="{ marginTop: '0.5rem !important' }"
            />

            <div class="feedback-container">
              <BaseInput
                ref="phone"
                type="text"
                phoneNumber
                maxlength="15"
                label="Número de teléfono"
                placeholder="Ej: 300404179"
                v-model="form.phone"
                required="true"
                :style="{ marginTop: '0.5rem !important' }"
              />
              <div v-if="form.phone !== '' && !validPhone()">
                <p class="mt-1 text-xs text-left text-red-500">
                  {{ feedbackPhone }}
                </p>
              </div>
            </div>
          </div>
          <!-- Juridica -->
          <div
            v-if="form.person_type_cod === 2"
            class="grid gap-5 grid-cols-1 md:grid-cols-2"
          >
            <BaseInput
              ref="names"
              type="text"
              label="Nombres del representante legal"
              v-model="form.first_name"
              placeholder="Ej: Julio Roman"
              :required="true"
              :maxlength="25"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <BaseInput
              ref="last_name"
              type="text"
              label="Apellidos del representante legal"
              v-model="form.last_name"
              placeholder="Ej: Henriquez"
              :required="true"
              :maxlength="24"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <BaseInput
              ref="nit"
              type="text"
              label="NIT"
              placeholder="4893461894-1"
              v-model="form.identification_number"
              :required="true"
              identification="3"
              :maxlength="14"
              :style="{ marginTop: '0.5rem !important' }"
            />

            <BaseInput
              ref="company_name"
              type="text"
              id="razonSocial"
              label="Razón social"
              placeholder="Empresa S.A"
              v-model="form.business_name"
              :required="true"
              :maxlength="50"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <div class="phone-container">
              <BaseInput
                ref="phone"
                type="text"
                phoneNumber
                maxlength="15"
                label="Número de teléfono"
                placeholder="Ej: 300404179"
                v-model="form.phone"
                required="true"
                :style="{ marginTop: '0.5rem !important' }"
              />
              <div v-if="form.phone !== '' && !validPhone()">
                <p class="mt-1 text-xs text-left text-red-500">
                  {{ feedbackPhone }}
                </p>
              </div>
            </div>
          </div>
          <div class="w-full mb-2">
            <BaseRadio
              name="hasEmail"
              id="hasEmail"
              label="¿Tiene correo electrónico?"
              :radioOptions="optionsYN"
              v-model="form.hasEmail"
              required="true"
            />
          </div>
          <div class="grid gap-5 grid-cols-1 md:grid-cols-2">
            <BaseInput
              v-if="form.hasEmail == 1"
              ref="email"
              type="text"
              label="Correo electrónico"
              v-model="form.email"
              placeholder="ejemplo@ejemplo.com"
              required="true"
              autocomplete="username"
              :style="{ marginTop: '0.5rem !important' }"
            />

            <div class="feedback-container">
              <BaseInput
                type="text"
                label="Dirección física"
                v-model="form.address"
                placeholder="Calle 123 avenida 123"
                :maxlength="50"
                @input="validateAddress"
                :style="{ marginTop: '0.5rem !important' }"
              />
              <p
                v-if="addressError"
                class="text-xs text-left text-red-500 mt-1"
              >
                {{ addressError }}
              </p>
            </div>

            <BaseInput
              v-if="form.hasEmail == 2"
              ref="email"
              type="text"
              label="Correo electrónico de notificación"
              v-model="form.email_notification"
              placeholder="ejemplo@ejemplo.com"
              :required="form.hasEmail == 2"
              :style="{ marginTop: '0.5rem !important' }"
            />

            <BaseSelect
              ref="country"
              id="country"
              label="Seleccione un país"
              :selectOptions="clonedOptionsCountries"
              v-model="form.pais_cod"
              :required="true"
              :style="{ marginTop: '0.5rem !important' }"
            />

            <BaseSelect
              v-if="form.pais_cod == '170'"
              ref="department"
              id="department"
              label="Seleccione un departamento"
              :selectOptions="formOptions.optionsDepartments"
              v-model="form.departamento_cod"
              :style="{ marginTop: '0.5rem !important' }"
            />
            <BaseSelect
              v-if="form.pais_cod == '170'"
              ref="city"
              id="city"
              label="Seleccione un municipio"
              :selectOptions="optionsMunicipalities"
              v-model="form.municipio_cod"
              :style="{ marginTop: '0.5rem !important' }"
            />
          </div>
        </div>
      </div>
      <div
        v-if="feedback"
        class="w-11/12 flex mx-auto justify-center items-center gap-5 p-1"
      >
        <p class="my-1 text-xs text-center text-red-500">{{ feedback }}</p>
      </div>
      <div class="w-11/12 flex mx-auto justify-center items-center gap-5 p-5">
        <a-button
          class="w-11/12 gap-2 sm:mt-auto mt-3 lg:11/12"
          type="danger"
          ghost
          shape="round"
          @click="close"
        >
          CANCELAR
        </a-button>

        <a-button
          type="primary"
          html-type="submit"
          :block="true"
          shape="round"
          :disabled="disabledSaveBtn"
        >
          GUARDAR
        </a-button>
      </div>
    </form>
    <div class="absolute w-full h-full flex items-center justify-center">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </Modal>
</template>
<script>
export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    isEdit: {
      type: Boolean,
      required: true,
    },
    formApplicant: {
      type: Object,
      required: true,
    },
    optionsCountries: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      addressError: "",
      clonedOptionsCountries: [],
      emailPattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      feedback: "",
      feedbackIdentificationNumber: "",
      feedbackPhone: "",
      frequency: [
        { id: "1", name: "Semanal" },
        { id: "2", name: "Quincenal" },
        { id: "3", name: "Mensual" },
      ],
      form: {},
      optionsYN: [
        { id: 1, name: "Sí" },
        { id: 2, name: "No" },
      ],
      optionsMunicipalities: [],
      disabledSaveBtn: true,
    };
  },
  created() {

    const colombiaRegister = {
      code: "170",
      id: "170",
      name: "COLOMBIA",
    };

    this.clonedOptionsCountries = [...this.optionsCountries];

    // Verificar si "Colombia" ya existe en la lista de países
    const exists = this.clonedOptionsCountries.some(
      (country) => country.id === colombiaRegister.id
    );

    if (!exists) {
      this.clonedOptionsCountries.unshift(colombiaRegister);
    }

    this.form = {
      ...this.formApplicant,
    };
    if (this.form.person_type_cod) {
      this.disabledSaveBtn = false;
    }
  },
  watch: {
    "form.person_type_cod": function () {
      if (this.form.person_type_cod) {
        this.disabledSaveBtn = false;
      }
    },
    "form.departamento_cod": async function () {
      if (this.form.departamento_cod == "") {
        return;
      }
      let options = await this.$store.dispatch(
        "form/loadMunicipalitiesOptions",
        { department: this.form.departamento_cod }
      );
      this.optionsMunicipalities = options;

      if (this.form.municipio_cod) {
        const municipioValido = this.optionsMunicipalities.some(
          (municipio) => municipio.id === this.form.municipio_cod
        );
        if (!municipioValido) {
          this.form.municipio_cod = "";
        }
      }
    },
  },
  methods: {
    async saveSettings() {
      if (this.form.person_type_cod === 2) {
        this.form.identification_type_cod = 3;
      }

      if (!this.checkForm()) {
        return;
      }

      if (this.form.first_name && this.form.last_name) {
        this.form.full_name = `${this.form.first_name} ${this.form.last_name}`;
      }

      if (this.form.identification_type_cod) {
        const identificationType =
          this.$store.state.form?.optionsIdentificationType?.find(
            (item) => item.id === Number(this.form.identification_type_cod)
          );

        this.form.identification_type_cod = String(identificationType.id);
        this.form.identification_type_nombre = identificationType.name;
      }

      if (this.form.departamento_cod) {
        this.form.departamento_nombre = this.getNameById(
          this.formOptions.optionsDepartments,
          this.form.departamento_cod
        );
      }

      if (this.form.municipio_cod) {
        this.form.municipio_nombre = this.getNameById(
          this.optionsMunicipalities,
          this.form.municipio_cod
        );
      }

      if (this.form.pais_cod) {
        this.form.pais_nombre = this.getNameById(
          this.clonedOptionsCountries,
          this.form.pais_cod
        );

        if (this.form.pais_cod != "170") {
          this.form.departamento_cod = "";
          this.form.departamento_nombre = "";
          this.form.municipio_cod = "";
          this.form.municipio_nombre = "";
        }
      }

      if (this.isEdit) {
        this.$emit("editApplicantModal", this.form);
      } else {
        this.$emit("addApplicantModal", this.form); // Emitir el evento con el contenido de form
      }
    },

    checkForm() {
      this.feedback = "";
      let valid = false;
      let refs = { ...this.$refs };

      for (let ref in refs) {
        if (
          (refs[ref] && refs[ref].required && refs[ref].value === "") ||
          refs[ref]?.invalid
        ) {
          let y =
            refs[ref].$el.getBoundingClientRect().top + window.scrollY - 100;
          window.scrollTo(0, y);
          refs[ref].setInvalid(true);
          return valid;
        }
      }

      let regex = /[^a-zñÑ\s]/i;
      let regexNIT = /[0-9]{7,}\-[0-9]{1}/;
      if (
        regex.test(this.form.first_name + "") ||
        regex.test(this.form.last_name + "")
      ) {
        this.feedback =
          "En los campos nombres y apellidos no se permiten carácteres especiales ni acentos";
        return valid;
      }

      if (this.$validator.isEmpty(this.form.first_name + "")) {
        this.feedback = "Introduzca el nombre completo del cliente";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.last_name + "")) {
        this.feedback = "Introduzca los apellidos del cliente";
        return valid;
      }

      if (this.$validator.isEmpty(this.form.identification_number + "")) {
        this.feedback = "Introduzca un número de identificación válido";
        return valid;
      }
      if (this.$validator.isEmpty(this.form.identification_type_cod + "")) {
        this.feedback = "Seleccione un tipo de identificación";
        return valid;
      }
      // Validación para la cédula de ciudadanía
      if (
        this.form.identification_type_cod == 1 &&
        !this.$validator.isNumeric(this.form.identification_number + "")
      ) {
        this.feedback = "Ingrese un número válido de documento";
        return valid;
      }

      // Validación usuarios Juridicos
      if (this.form.person_type_cod == 2) {
        if (regex.test(this.form.business_name + "")) {
          this.feedback =
            "En el campo razón social no se permite caracteres especiales ni acentos";
          return valid;
        }

        // Validación para NIT
        if (
          this.form.identification_type_cod == 3 &&
          !regexNIT.test(this.form.identification_number + "")
        ) {
          this.feedback = "Ingrese un NIT válido";
          return valid;
        }

        // Validación para razón social
        if (this.$validator.isEmpty(this.form.business_name + "")) {
          this.feedback = "Introduzca la razón social";
          return valid;
        }
      }

      if ((this.form.identification_number + "").length > 15) {
        this.feedback =
          "La longitud máxima del número de identificación debe ser 15.";
        return valid;
      }

      if (this.form.phone && this.form.phone.toString().length < 7) {
        this.feedback = "Ingrese un número de teléfono válido.";
        return valid;
      }

      if (this.form.phone && this.form.phone.toString().length > 15) {
        this.feedback = "El número de teléfono no puede ser mayor a 15.";
        return valid;
      }
      if (this.form.birth_date) {
        if (
          this.form.person_type == 1 &&
          !this.$validator.isDate(this.form.birth_date)
        ) {
          this.feedback = "Introduzca una fecha de nacimiento válida";
          return valid;
        }
      }
      if (this.form.hasEmail == 1) {
        if (
          this.emailPattern.test(this.form.address) &&
          (this.feedback =
            "No se permite ingresar un correo electrónico en la dirección física")
        ) {
          return valid;
        }
        if (!this.$validator.isEmail(this.form.email)) {
          this.feedback = "Introduzca un correo válido";
          return valid;
        }
      } else {
        // Validaciones para usuario sin correo
        if (
          this.form.email_notification &&
          !this.emailPattern.test(this.form.email_notification)
        ) {
          this.feedback =
            "Introduzca un correo electrónico de notificación válido";
          return valid;
        }
        if (
          this.emailPattern.test(this.form.address) &&
          (this.feedback =
            "No se permite ingresar un correo electrónico en la dirección física")
        ) {
          return valid;
        }
      }

      // Debe existir algún medio de contacto correo electronico o dirección física o correo de notificación
      if (
        !this.form.email &&
        !this.form.address &&
        !this.form.email_notification
      ) {
        this.feedback =
          "Debe ingresar al menos un medio de contacto: correo electrónico, dirección física o correo de notificación";
        return valid;
      }

      if (this.isEdit === false) {
        const index = this.data.findIndex(
          (item) =>
            item.identification_number === this.form.identification_number
        );
        if (index !== -1) {
          this.feedback =
            "El número de identificación ingresado ya está asociado a uno de los registros existentes.";
          return valid;
        }
      }

      valid = true;
      this.feedback = "";
      return valid;
    },

    close() {
      this.$emit("close", { success: false });
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
              return h(
                "a-button",
                {
                  props: {
                    type: "primary",
                    size: "small",
                  },
                  on: {
                    click: () => this.$notification.close(key),
                  },
                },
                "Entendido"
              );
            }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
    validPhone() {
      if (!this.isValidPhone) {
        this.feedbackPhone = "Introduzca un número de teléfono válido";

        return false;
      }
      return true;
    },
    validPpt() {
      if (!this.isValidPpt) {
        this.feedbackIdentificationNumber =
          "Introduzca un número de identificacion válido";

        return false;
      }
      return true;
    },
    validateAddress() {
      if (/[^\s@]+@[^\s@]+\.[^\s@]+/.test(this.form.address)) {
        this.addressError =
          "No se permite el ingreso de un correo electrónico en este campo";
      } else {
        this.addressError = "";
      }
    },
    getNameById(lista, idBuscado, defaultValue = null) {
      try {
        const elementoEncontrado = lista.find(
          (elemento) => String(elemento.id) === idBuscado
        );
        if (elementoEncontrado) {
          return elementoEncontrado.name;
        }
      } catch (error) {
        console.error(
          "getNameById - error:",
          error,
          "lista",
          lista,
          "idBuscado",
          idBuscado
        );
      }
      return defaultValue;
    },
  },
  computed: {
    formOptions: function () {
      return this.$store.state.form;
    },
    isValidPhone: function () {
      if (!this.form.phone) return true;
      if (
        this.form.phone.toString().length >= 7 &&
        this.form.phone.toString().length <= 15
      )
        return true;

      return false;
    },
    isValidPpt: function () {
      if (
        this.form.identification_type_cod != 10 ||
        !this.form.identification_number
      ) {
        return true;
      }
      if (/(?<![.\d])\d+(?![.\d])/.test(this.form.identification_number)) {
        return true;
      }
      return false;
    },
    customIdentificationType: function () {
      return this.$store.state.form?.optionsIdentificationType?.filter(
        (identification) => identification.id != 3
      );
    },
  },
};
</script>

<style scoped>
.modal-content-scroll {
  max-height: 70vh;
  overflow-y: auto;
}

.feedback-container {
  display: flex;
  flex-direction: column;
  align-items: left;
}
</style>
